import React, { Component } from 'react';
import styled from 'styled-components';
import { computed } from 'mobx';
import { WithToastMessage } from 'as-ducati-core';
import { observer, inject } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as classNames from 'context-boards/classNames';
import { SummaryInfo } from 'components/summary-info';
import { SummaryHeaderInline } from 'common/styledElements';
import theme from 'common/theme';
import { ToggleHeight } from 'common/toggle-content';
import GroupName from 'common/groupName';
import OverlayTriggerX from 'common/overlayTriggerX';
import PopoverX from 'common/popover-x';
import { TOGGLE_SUMMARY_HEIGHT } from 'stores/constants';
import { getLatestOwnerChangedDate } from 'utils/helper';

const Content = styled.div`
  margin: 10px;
  color: ${theme.body_secondary_text_color_default};
  word-break: break-word;
`;

@injectIntl
@inject('agreement', 'eventful', 'stores')
@observer
class LibraryDocumentSummary extends Component {
  // Creator + the maximum number of sharees to show in the popover (maximum total = 10).
  maxShareeCount = 9;

  @computed
  get agreementInfo() {
    return this.props.agreement.attributes;
  }

  @computed
  get events() {
    return this.props.agreement.events;
  }

  @computed
  get shares() {
    return this.props.agreement.shares;
  }

  componentDidUpdate() {
    this.props.eventful.fireUpdate({
      component: 'library-document-summary-info',
      type: 'summary'
    });
  }

  getLibraryDocumentDetail() {
    const { formatMessage } = this.props.intl,
      templateTypes = this.agreementInfo.templateTypes.join(','),
      libraryDocumentCreator = this.agreementInfo.creatorName
        ? this.agreementInfo.creatorName + ' (' + this.agreementInfo.creatorEmail + ')'
        : this.agreementInfo.creatorEmail;

    const sharingMode = this.agreementInfo.sharingMode;
    const isShared = this.props.agreement.isSharedByUser();
    const isSharedWithMe = this.isTemplateSharedWithMe();
    let intlSharingModePopover = '';
    let intlSharingMode = '';

    if (this.agreementInfo.groupId && this.props.isSelf) {
      intlSharingMode = <GroupName isCreator={this.props.isSelf} />;
    } else if (sharingMode) {
      if (
        sharingMode === this.props.stores.Api.LibraryDocuments.LibraryDocument.SHARING_MODE.USER
      ) {
        const {
          intlSharingMode: customIntlSharingMode,
          intlSharingModePopover: customIntlSharingModePopover
        } = this.customizeIntlSharingMode();
        intlSharingMode = customIntlSharingMode;
        intlSharingModePopover = customIntlSharingModePopover;
      } else if (
        sharingMode === this.props.stores.Api.LibraryDocuments.LibraryDocument.SHARING_MODE.MIXED
      ) {
        const {
          intlSharingMode: customIntlSharingMode,
          intlSharingModePopover: customIntlSharingModePopover
        } = this.customizeGroupSharingMode();
        intlSharingMode = customIntlSharingMode;
        intlSharingModePopover = customIntlSharingModePopover;
      } else {
        intlSharingMode = formatMessage({
          id: 'library_documents.summary_info.sharing_mode.' + sharingMode.toLowerCase()
        });
      }
    }

    let intlTemplateType = '';

    switch (templateTypes) {
      case 'DOCUMENT':
      case 'FORM_FIELD_LAYER':
        intlTemplateType = formatMessage({
          id: 'library_documents.summary_info.template_type.' + templateTypes.toLowerCase()
        });
        break;

      case 'DOCUMENT,FORM_FIELD_LAYER':
        intlTemplateType = formatMessage({
          id: 'library_documents.summary_info.template_type.both'
        });
        break;

      default:
        break;
    }

    const ownerChangedDate = getLatestOwnerChangedDate(this.events);

    let overlayProps = {
      container: window.document.body,
      placement: 'left',
      trigger: ['click', 'hover']
    };

    return (
      <section className={this.props.className}>
        <SummaryInfo
          name={this.agreementInfo.name}
          createdDate={this.agreementInfo.createdDate}
          creatorName={libraryDocumentCreator}
          ownershipChangedDate={ownerChangedDate}
        />
        <Content className={classNames.LIBRARY_DOCUMENT_TEMPLATE_TYPE}>
          <SummaryHeaderInline>
            <FormattedMessage id="library_documents.summary_info.template_type.header" />
          </SummaryHeaderInline>
          <br />
          {intlTemplateType}
        </Content>
        <Content className={classNames.LIBRARY_DOCUMENT_SHARED_WITH}>
          <SummaryHeaderInline>
            <FormattedMessage id="library_documents.summary_info.sharing_mode.header" />
          </SummaryHeaderInline>
          <br />
          <OverlayTriggerX resizeOverlay={false} {...overlayProps}>
            <span id="shared_with_text">{intlSharingMode}</span>
            {(isShared && !isSharedWithMe) ||
            sharingMode ===
              this.props.stores.Api.LibraryDocuments.LibraryDocument.SHARING_MODE.MIXED ? (
              <PopoverX id="shared_with_popover" height="50vw">
                <span>
                  {intlSharingModePopover.split(',').map((nameAndEmail, index) => (
                    <span key={index}>
                      {nameAndEmail}
                      <br />
                    </span>
                  ))}
                </span>
              </PopoverX>
            ) : (
              <div />
            )}
          </OverlayTriggerX>
        </Content>
      </section>
    );
  }

  render() {
    return (
      <ToggleHeight
        className={classNames.SUMMARY_INFO_SECTION}
        analyticsSubType="summary"
        height={TOGGLE_SUMMARY_HEIGHT}
        style={{ marginBottom: '1ex' }}
      >
        {this.getLibraryDocumentDetail()}
      </ToggleHeight>
    );
  }

  isTemplateSharedWithMe() {
    if (!this.props.agreement.isSharedByUser()) {
      return false;
    }

    const userId = this.props.stores.Env.user.seriouslySecureId;
    return this.agreementInfo.sharingGroupInfos.some(shareeInfo => shareeInfo.shareeId === userId);
  }

  /**
   * Customize the text under "Who can use this template" and its popover.
   * When scope=USER, perform the following customizations for "Who can use this template".
   * 1. 'Only me' - if the template is NOT shared.
   * 2. '<creatorName>, <shareeName1>' - if the template is shared with one other user.
   * 3. '<creatorName>, <shareeName1>' + # Others' - if the template is shared with more than one other users.
   *
   * Also customize the popover, which will contain the names AND emails of all the shared users upto 10 sharees.
   * @returns {{intlSharingModePopover: string, intlSharingMode: string}}
   */
  customizeIntlSharingMode() {
    const { formatMessage } = this.props.intl;
    const isShared = this.props.agreement.isSharedByUser();
    const isSharedWithMe = this.isTemplateSharedWithMe();
    let intlSharingModePopover = '';
    let intlSharingMode;

    if (isShared) {
      if (!isSharedWithMe) {
        const shareeCount = this.agreementInfo.sharingGroupInfos.length;
        const sharees = this.agreementInfo.sharingGroupInfos;
        const creatorName = this.agreementInfo.creatorName;
        const creatorEmail = this.agreementInfo.creatorEmail;

        // When the scope is USER and the template is shared with others
        const creatorNameAndEmail = `${creatorName} <${creatorEmail}>, `;
        let concatenatedNames = `${creatorName}, ${sharees[0].shareeName}`;

        if (shareeCount > 1) {
          const remainingCount = shareeCount - 1;
          concatenatedNames = formatMessage(
            { id: 'library_documents.summary_info.sharing_mode.others' },
            { names: concatenatedNames, count: remainingCount }
          );
        }

        const shareeNameAndEmails = sharees.slice(0, this.maxShareeCount).map(function (info) {
          return `${info.shareeName} <${info.shareeEmail}>`;
        });

        let concatenatedNameAndEmails = creatorNameAndEmail + shareeNameAndEmails.join(', ');

        if (shareeCount > this.maxShareeCount) {
          const remainingCount = shareeCount - this.maxShareeCount;

          concatenatedNameAndEmails = formatMessage(
            { id: 'library_documents.summary_info.sharing_mode.more' },
            { names_and_emails: concatenatedNameAndEmails, count: remainingCount }
          );
        }

        intlSharingModePopover = concatenatedNameAndEmails;
        intlSharingMode = concatenatedNames;
      }
    } else {
      // When the scope is USER and the template is not shared
      intlSharingMode = formatMessage({ id: 'library_documents.summary_info.sharing_mode.user' });
    }
    return { intlSharingMode, intlSharingModePopover };
  }

  customizeGroupSharingMode() {
    const { formatMessage } = this.props.intl;
    let intlSharingMode = '';
    let intlSharingModePopover = '';

    const shareesCount = this.shares.size();

    if (shareesCount > 0) {
      const shareeNames = this.shares.pluck('name');
      // Create a popover string by joining names with commas
      intlSharingModePopover = shareeNames.join(', ');

      // If there are multiple sharees, concatenate appropriately
      if (shareesCount > 1) {
        const remainingCount = shareesCount - 1;
        intlSharingMode = formatMessage(
          { id: 'library_documents.summary_info.sharing_mode.others' },
          { names: shareeNames[0], count: remainingCount }
        );
      } else {
        // For just one sharee, show the name without formatting
        intlSharingMode = shareeNames[0];
      }
    }
    return { intlSharingMode, intlSharingModePopover };
  }
}

export default WithToastMessage(LibraryDocumentSummary);
