import { AGREEMENT_TYPES as AT } from 'as-ducati-utils';
import stores from 'stores';
import { CONTEXT_BOARD_TYPES as CB } from 'stores/constants';
import AgreementCB from './agreement';
import LibDocCB from './library-document';
import WidgetCB from './widget';
import MegaSignCB from './megaSign';
import PowerAutomateCB from './powerAutomate';

const CONTEXT_BOARD_MAP = {
  [CB.AGREEMENT]: AgreementCB,
  [CB.LIBRARY_DOCUMENT]: LibDocCB,
  [CB.WIDGET]: WidgetCB,
  [CB.MEGASIGN]: MegaSignCB
};

/**
 * Get canonical agreement kind for type
 *
 * @param type {string} an agreement type
 * @return {string} a canonical type
 */
export const getAgreementKind = (type = '') => {
  type = type || stores.agreementType;
  if (!type) return '';
  switch (type) {
    case AT.FILL_SIGN:
    case AT.ARCHIVE:
    case AT.ESIGN:
    case AT.MEGASIGN_CHILD:
    case AT.WIDGET_INSTANCE:
      return CB.AGREEMENT;
    case AT.LIBRARY_DOCUMENT:
      return CB.LIBRARY_DOCUMENT;
    case AT.WIDGET:
      return CB.WIDGET;
    case AT.MEGASIGN_PARENT:
      return CB.MEGASIGN;
    default:
      throw new Error(`Agreement type "${type}" not supported.`);
  }
};

/**
 * context board selector
 *
 * @return {Component} context board class for given type
 */
export const getContextBoard = () => {
  if (stores.Env.powerAutomateMode) {
    return PowerAutomateCB;
  }
  stores.agreementKind = getAgreementKind();
  return CONTEXT_BOARD_MAP[stores.agreementKind];
};

/**
 * Get the primary REST model for agreement type
 * @return {Backbone.Model}
 */
export const getContextBoardModel = () => getContextBoard().getRestModel(stores.Api);
