import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { action, computed } from 'mobx';
import { Eventful, withEventfulToast } from 'common/withEventful';
import withErrorBoundary from 'common/withErrorBoundary';
import withApiReady from 'common/withApiReady';
import { CloseButton } from 'common/actionButton';
import log from 'utils/logger';
import Wait from '@react/react-spectrum/Wait';

const Container = styled.section`
  padding: 8px;
`;

const StyledWait = styled(Wait)`
  &.spectrum-CircleLoader {
    margin: 50% auto;
    display: flex;
  }
`;

// PA Types
const PowerAutomateCBTypes = {
  AUTOMATE_ARCHIVAL: 'automateArchival',
  AUTOMATE_NOTIFICATIONS: 'automateNotifications'
};

// Mapping function to get Power Action Types
export const getCategoryNameForPowerAutomateCBTypes = powerAutomateCBType => {
  switch (powerAutomateCBType) {
    case PowerAutomateCBTypes.AUTOMATE_ARCHIVAL:
      return 'Archival';
    case PowerAutomateCBTypes.AUTOMATE_NOTIFICATIONS:
      return 'Notification';
    default:
      return powerAutomateCBType; // Return original value if no match
  }
};

@inject('stores')
@observer
class PowerAutomateCB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: props.powerAutomateCBLoading !== undefined ? props.powerAutomateCBLoading : true // Initially, we show the spinner
    };
  }

  @computed
  get canRender() {
    return this.props.stores.Env.loggedIn;
  }

  componentDidMount() {
    if (window.renderAdvancedWorkflowsCoreCDN) {
      this.renderPowerAutomateCB();
    } else {
      window.App.Utils.loadjs([window.App.Env.plugins.advancedWorkflowsCore.pluginUrl], {
        returnPromise: true
      })
        .then(() => {
          this.renderPowerAutomateCB();
        })
        .catch(error => {
          this.error = error;
        });
    }
  }

  componentWillUnmount() {
    this.updatePowerAutomateProps(false, '');
  }

  renderPowerAutomateCB() {
    Promise.all([
      window.App.Env.plugins.advancedWorkflowsConnect.ready(),
      window.App.Env.plugins.advancedWorkflowsCore.ready()
    ])
      .catch(error => {
        console.error(
          'Error in resolving promise for advancedWorkflowsConnect and advancedWorkflowsCore ready methods',
          error
        );
      })
      .then(() => {
        if (window.renderAdvancedWorkflowsCoreCDN) {
          const multiMode = this.props.multiMode;
          const powerAutomateMode = this.props.powerAutomateMode;
          const { agreementList, agreementId, stores } = this.props;
          const agreementsContext = multiMode
            ? agreementList.map(item => ({
                agreementId: item.agreementId,
                agreementName: item.name
              }))
            : powerAutomateMode
            ? []
            : [
                {
                  agreementId: agreementId,
                  agreementName: stores.agreement.get('name')
                }
              ];

          const renderConfig = {
            pageName: 'featured',
            showMinimalisticView: true,
            agreementsContext,
            categoryName: getCategoryNameForPowerAutomateCBTypes(
              stores.powerAutomate.powerAutomateCBType || powerAutomateMode
            )
          };

          return window.renderAdvancedWorkflowsCoreCDN(
            renderConfig,
            'power-automate-context-board',
            true
          );
        }
      })
      .catch(error => {
        console.error('Error while rendering AdvancedWorkflowsCoreCDN', error);
      });
  }

  onCloseClick = () => {
    this.updatePowerAutomateProps(false, '');
    if (this.props.eventful) {
      this.props.eventful.fireEvent(Eventful.EVENTS.CloseContextBoard);
    }
  };

  @action
  updatePowerAutomateProps = (powerAutomateCBVisible, powerAutomateCBType) => {
    this.props.stores.Env.powerAutomateMode = powerAutomateCBVisible;
    this.props.stores.powerAutomate.powerAutomateCBVisible = powerAutomateCBVisible;
    this.props.stores.powerAutomate.powerAutomateCBType = powerAutomateCBType;
  };

  render() {
    log.info(`Rendering pa-embed: ${this.canRender}`);
    if (!this.canRender) {
      return null;
    }

    const { formatMessage } = this.props.stores.Intl;
    const multiMode = this.props.multiMode;
    const powerAutomateMode = this.props.powerAutomateMode;
    const isCBTypeAutomateArchival =
      this.props.stores.powerAutomate.powerAutomateCBType ===
        PowerAutomateCBTypes.AUTOMATE_ARCHIVAL ||
      this.props.powerAutomateMode === PowerAutomateCBTypes.AUTOMATE_ARCHIVAL;
    const heading = isCBTypeAutomateArchival
      ? formatMessage({ id: 'power_automate.context_board.automate.archival.heading' })
      : formatMessage({ id: 'power_automate.context_board.automate.notification.heading' });
    const subHeading = multiMode
      ? formatMessage(
          { id: 'power_automate.context_board.automate.multi_select.sub_heading' },
          { count: this.props.agreementList.length }
        )
      : powerAutomateMode
      ? isCBTypeAutomateArchival
        ? formatMessage({ id: 'power_automate.context_board.automate.archival.sub_heading' })
        : formatMessage({ id: 'power_automate.context_board.automate.notification.sub_heading' })
      : this.props.stores.agreement.get('name');

    return (
      <Container className={this.props.className}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            margin: '11px 0px 11px 10px'
          }}
        >
          <div>
            <div
              id="power-automate-context-board-heading"
              style={{ fontSize: '14px', fontWeight: 'bold' }}
            >
              {heading}
            </div>
            <div
              id="power-automate-context-board-subheading"
              style={{
                width: '230px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {subHeading}
            </div>
          </div>
          <CloseButton
            id="power-automate-context-board-close-button"
            alignRight
            analyticsSubType={'context-board'}
            onClick={this.onCloseClick}
          />
        </div>
        <div id="power-automate-context-board">
          <StyledWait />
        </div>
      </Container>
    );
  }
}

export default withErrorBoundary(
  withApiReady(withEventfulToast(PowerAutomateCB, 'PowerAutomateCB'))
);
