import React from 'react';
import { observer } from 'mobx-react';
import ModifyIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_ModifyAgreement_18_N.svg';
import ViewAgrIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_OpenAgreement_18_N.svg';
import UseTemplateIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_UseTemplate_18_N.svg';
import EditIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_EditUsing_18_N.svg';
import _ from 'lodash/core';
import { AGREEMENT_TYPES } from 'as-ducati-utils';
import { VIEWS } from 'common/tabView';
import { CONTEXT_BOARD_TYPES, STARTUP_ACTIONS } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import PostView from 'components/post-views';
import ExportFormDataView from 'components/export-data';
import AuditTrail from 'components/audit-trail';
import CancelView from 'components/cancel';
import DeleteView from 'components/delete';
import RestoreView from 'components/restore';
import DownloadPDF from 'components/download-pdf';
import ShareAgr from 'components/share';
import RemindersView from 'components/reminders';
import OriginatorFiles from 'components/originator-files';
import Notes from 'components/notes';
import AutomateAction, {
  isPowerAutomateInContextActionsV1Visible
} from 'components/automate-action';
import ReportAbuseView from 'components/report-abuse';
import HideShowView from 'components/hide-show';
import Thumbnail from 'components/thumbnail';
import DownloadSignerIdentityView from '../download-signer-id';
import UploadSignedDocumentView from '../upload-signed-document';
import FileAttachmentsView from '../file-attachments';
import * as classNames from '../../classNames';

/**
 * Single action components: actions to be used in the Actions section of the context board.
 *
 * NOTE: THESE ARE USED BY THE MEGASIGN PARENT AGREEMENT VIEW AS WELL AS THE MAIN AGREEMENTS VIEW.
 */

const navUrl = type => `/account/advancedWorkflow?category=${type}#/workflowTemplates`;
const navigateToAutomateTemplate = navUrl('Archival');
const navigateToNotificationsTemplate = navUrl('Notification');

export const ViewAgr = observer(props => {
  const labelId = props.labelId || 'actions.view.agreement';
  return props.isManageV4 ? (
    <PostView
      {...props}
      name={'DOCUMENT'}
      analyticsEventType={analyticsFor.VIEW_AGR_LINK}
      className={classNames.VIEW_AGREEMENT_SECTION}
      labelId={labelId}
      icon={<ViewAgrIcon />}
      switchAcctWarning={false}
      openComponent={props.startupAction === STARTUP_ACTIONS.VIEW}
      openComponentDelay={props.startupActionDelay}
    />
  ) : null;
});

export const SaveAsTemplate = observer(props => {
  return props.agreement.isSender ? (
    <PostView
      {...props}
      name={'CREATE_TEMPLATE'}
      analyticsEventType={analyticsFor.CREATE_TEMPLATE}
      className={classNames.CREATE_TEMPLATE_SECTION}
      labelId={'actions.create_template'}
      icon={<UseTemplateIcon />}
    />
  ) : null;
});

export const Edit = props => {
  if (props.hasDocRetention) return null;

  let name = 'AUTHORING';

  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  props.agreement.members && props.agreement.members.observable.participantSets; // eslint-disable-line

  if (props.stores.agreementType === AGREEMENT_TYPES.LIBRARY_DOCUMENT) {
    name = 'EDIT';
  }

  const labelId = props.labelId || 'actions.edit_agreement';
  const dcWorkflowAction =
    props.isDCWeb &&
    (props.stores.agreementType === AGREEMENT_TYPES.WIDGET ||
      props.stores.agreementType === AGREEMENT_TYPES.MEGASIGN_PARENT);

  // for widgets, only owner is allowed to edit.
  let canShareeModifyWebform = false; // change to true if there is sharee and sharee canModify
  const agrOwnerId = props.agreement.get('ownerId');
  const sharerId = props.stores.accountSharing.sharer.get('userId');
  if (sharerId && props.stores.agreementType === AGREEMENT_TYPES.WIDGET) {
    name = props.stores.UserSettings.isModifyWebFormInFlightFeatureEnabled()
      ? 'MODIFY'
      : 'AUTHORING';
    const isWidgetOwnedBySharer = agrOwnerId === sharerId;
    const canModify =
      props.stores.accountSharing.isAcctSwitched() && props.stores.accountSharing.sharer.canSend();
    // Release 14.2 allow sharee to edit webform when aasWebformModifyRollout is true
    // aasWebformEditRollout added from PR https://git.corp.adobe.com/Adobesign/core_app/pull/8818,
    // and it was renamed in PR 8909 as context-dropin plugin env property. Rollout switch will be removed in 15.0
    const aasWebformModifyRollout = props.stores.Env.aasWebformModifyRollout;
    canShareeModifyWebform = isWidgetOwnedBySharer && canModify && aasWebformModifyRollout;
  }

  let isUserAllowedToEdit;
  if (props.stores.agreementType === AGREEMENT_TYPES.WIDGET) {
    isUserAllowedToEdit = agrOwnerId === props.stores.User.getId() || canShareeModifyWebform;
  } else if (props.stores.agreementType === AGREEMENT_TYPES.ESIGN) {
    isUserAllowedToEdit = !props.stores.accountSharing.cannotSend();
  } else {
    isUserAllowedToEdit = true;
  }
  // Regular agreement or Fill & Sign
  return isUserAllowedToEdit ? (
    <PostView
      {...props}
      labelId={labelId}
      altName={'FILL_SIGN'}
      name={name}
      useSignURL={dcWorkflowAction}
      analyticsEventType={analyticsFor.EDIT_AGREEMENT}
      className={classNames.AGREEMENT_EDIT}
      icon={<EditIcon />}
      openComponent={props.startupAction === STARTUP_ACTIONS.EDIT}
      openComponentDelay={props.startupActionDelay}
    />
  ) : null;
};

export const Cancel = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  props.agreement.members.observable.participantSets; // eslint-disable-line

  // observable to react to
  props.stores.facets.state; // eslint-disable-line

  // Making sure there are no child megasign agreements that have already been signed
  const isMegaSign = props.type === CONTEXT_BOARD_TYPES.MEGASIGN;

  const canCancelRegularAgreement =
    props.agreement.isAuthoring() ||
    props.agreement.isOutForCompletion() ||
    // isWaitingForNotarization is not available for a MegaSign and throws an error
    (!isMegaSign && props.agreement.isWaitingForNotarization());

  const canCancelMegaSign = () => {
    // For a MegaSign with no child agreements created, props.stores.facet.state is undefined
    // For a MegaSign with child agreements created, props.stores.facet.state is defined
    const childAgreementsNotCreatedYet = !props.stores.facets.state;
    const isMegaSignUnsigned =
      props.stores.facets.state && _.isUndefined(props.stores.facets.state.SIGNED);

    return (
      props.agreement.isAuthoring() ||
      props.agreement.isPrefill() ||
      (childAgreementsNotCreatedYet && props.agreement.isOutForCompletion()) ||
      (isMegaSignUnsigned && props.agreement.isOutForCompletion())
    );
  };

  const isAgreementEligibleForCancel = isMegaSign ? canCancelMegaSign() : canCancelRegularAgreement;

  //Adv Account sharing can cancel if they have modify or send permissions
  const canCancel =
    props.agreement.isSender &&
    isAgreementEligibleForCancel &&
    !props.stores.accountSharing.cannotModify();

  return canCancel ? (
    <CancelView type={props.type || CONTEXT_BOARD_TYPES.AGREEMENT} {...props} />
  ) : null;
});

export const AutomateArchival = observer(props => {
  const analytics = analyticsFor(analyticsFor.AUTOMATE_ARCHIVAL);
  const canAutomateArchival = isPowerAutomateInContextActionsV1Visible(props);

  return canAutomateArchival ? (
    <AutomateAction
      componentName="automateArchival"
      navigateTo={navigateToAutomateTemplate}
      labelId="automate.archival.title"
      className={classNames.AUTOMATE_ARCHIVAL_SECTION}
      analytics={analytics}
      startupAction={STARTUP_ACTIONS.AUTOMATE_ARCHIVAL}
      {...props}
    />
  ) : null;
});

export const AutomateNotifications = observer(props => {
  const analytics = analyticsFor(analyticsFor.AUTOMATE_NOTIFICATIONS);
  const canAutomateNotifications = isPowerAutomateInContextActionsV1Visible(props);

  return canAutomateNotifications ? (
    <AutomateAction
      componentName="automateNotifications"
      navigateTo={navigateToNotificationsTemplate}
      labelId="automate.notifications.title"
      className={classNames.AUTOMATE_NOTIFICATIONS_SECTION}
      analytics={analytics}
      startupAction={STARTUP_ACTIONS.AUTOMATE_NOTIFICATIONS}
      {...props}
    />
  ) : null;
});

export const Reminders = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  props.agreement.members.observable.participantSets; // eslint-disable-line
  const ccParticipant = props.agreement.members.findParticipantBySelf(
    /*isSelf*/ true,
    props.stores.Api.Agreements.Members.PERSONAS.CC
  );

  return (props.agreement.isSender || ccParticipant) &&
    // expired, archived, cancelled
    !props.agreement.isIncomplete() ? (
    <RemindersView
      {...props}
      expanded={props.expanded || false}
      onClick={() => props.setView(VIEWS.REMINDERS)}
    />
  ) : null;
});

export const Share = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  props.agreement.members.observable.participantSets; // eslint-disable-line
  // check the current user can share agreement or not.
  let visible =
    !props.agreement.isAuthoring() &&
    !props.agreement.isIncomplete() &&
    props.agreement.me.canShare();
  return visible ? (
    <ShareAgr type={props.type || CONTEXT_BOARD_TYPES.AGREEMENT} {...props} />
  ) : null;
});

export const DownloadOriginatorFiles = observer(props => {
  // observable to react to
  // [model for <other types>/documents]
  props.agreement.documents.observable.models; // eslint-disable-line

  // observable to react to
  // [model for agreement/documents]
  props.agreement.documents.observable.documents; // eslint-disable-line

  // TODO : remove isSender() condition once this pull request reaches production
  // https://git.corp.adobe.com/Adobesign/core_app/pull/12539
  const showOriginatorFiles =
    props.agreement.members.isSender() || props.agreement.me.canViewIndividualDocs();

  return showOriginatorFiles ? <OriginatorFiles {...props} /> : null;
});

export const ExportFormData = props =>
  props.agreement.get('hasFormFieldData') && props.agreement.isSender ? (
    <ExportFormDataView {...props} />
  ) : null;

// show-hide doesn't need to react to status change
export const HideShow = props => {
  if (!props.stores.Env.loggedIn || props.stores.Env.limitedApiAccess) return null;

  const participant = props.agreement.members.findParticipantBySelf(
    true,
    props.stores.Api.Agreements.Members.PERSONAS.ANY
  );

  return participant ? (
    <HideShowView
      className={classNames.HIDE_SHOW_SECTION}
      type={props.type || CONTEXT_BOARD_TYPES.AGREEMENT}
      participant={participant}
      openComponent={props.startupAction === STARTUP_ACTIONS.VISIBILITY}
      openComponentDelay={props.startupActionDelay}
    />
  ) : null;
};

export const ModifyAgr = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  props.agreement.members.observable.participantSets; // eslint-disable-line

  if (
    props.agreement.isCanceled() ||
    props.stores.accountSharing.cannotModify() ||
    (props.stores.accountSharing.isAcctSwitched() &&
      props.agreement.isAuthoring() &&
      props.stores.accountSharing.cannotSend())
  )
    return null;

  return (
    <PostView
      {...props}
      name={'MODIFY'}
      analyticsEventType={analyticsFor.MODIFY_AGREEMENT}
      className={classNames.AGREEMENT_MODIFY}
      labelId={'actions.modify'}
      icon={<ModifyIcon />}
    />
  );
});

export const DownloadSignerIdentity = props => {
  let canShowSIRAction = props.agreement.get('status') !== 'AUTHORING';
  if (
    !(
      canShowSIRAction &&
      props.agreement.members.isSender() &&
      props.stores.UserSettings.canDownloadSignerIdentityReport() &&
      props.agreement.get('hasSignerIdentityReport')
    )
  )
    return null;

  return <DownloadSignerIdentityView />;
};

export const UploadSignedCopy = observer(props => {
  props.agreement.members.observable.participantSets; // eslint-disable-line

  // Upload Signed Document is visible only for sender or an advanced account sharee with SEND or MODIFY permissions
  if (
    !props.stores.Env.loggedIn ||
    (props.stores.Env.hasSharer() && props.stores.accountSharing.cannotModify())
  )
    return null;

  const nextParticipantSets = props.agreement.members.findNextParticipantSets(),
    activeParticipants =
      nextParticipantSets.length === 1 &&
      nextParticipantSets[0].get('memberInfos').where({ status: 'ACTIVE' });

  const isSignerWithWitness = props.agreement.members.isSignerWithWitness(nextParticipantSets[0]);

  const canUpload =
    props.stores.UserSettings.canUploadSignature() &&
    props.agreement.me.canUploadSignedCopy() &&
    props.agreement.members.isSender() &&
    !props.agreement.isWidgetInstanceWaitingForVerification() &&
    (props.agreement.members.getWorkflow() !== 'HYBRID' || props.agreement.get('workflowId')) &&
    nextParticipantSets.length === 1 &&
    activeParticipants.length >= 1 &&
    nextParticipantSets[0].get('role') === 'SIGNER' &&
    !isSignerWithWitness;

  // feature only available for senders when the agreement is
  // out for completion and there's only one participant next in line
  return canUpload ? <UploadSignedDocumentView /> : null;
});

export const FileAttachments = observer(props => {
  // observable to react to
  // [model for <other types>/documents]
  props.agreement.documents.observable.models; // eslint-disable-line

  // observable to react to
  // [model for agreement/documents]
  props.agreement.documents.observable.documents; // eslint-disable-line
  return <FileAttachmentsView {...props} />;
});

export const ThumbnailView = observer(props => {
  // observable to react to
  // [model for <other types>/documents]
  props.agreement.documents.observable.models; // eslint-disable-line

  // observable to react to
  // [model for agreement/documents]
  props.agreement.documents.observable.documents; // eslint-disable-line

  return <Thumbnail className={classNames.THUMBNAIL_SECTION} {...props} />;
});

export const Delete = observer(props => {
  return props.stores.agreement.me.canDelete() ? <DeleteView {...props} /> : null;
});

export const Restore = observer(props => {
  return props.stores.agreement.me.canRestore() ? <RestoreView {...props} /> : null;
});

export const ReportAbuse = props => <ReportAbuseView {...props} />;

export const ContextualActionsMap =
  // The component itself will have logic in the render method to determine if the user can render itself or not - this is merely a list of components in the order that we want the action to be rendered depending on agreement state

  // NOTE: report abuse should be 5th so it shows up above the fold

  {
    OUT_FOR_SIGNATURE: [
      ViewAgr,
      SaveAsTemplate,
      Reminders,
      Cancel,
      DownloadPDF,
      AutomateNotifications,
      ReportAbuse,
      AuditTrail,
      ModifyAgr,
      HideShow,
      Share,
      Notes,
      DownloadOriginatorFiles,
      ExportFormData,
      FileAttachments,
      UploadSignedCopy,
      DownloadSignerIdentity,
      Delete
    ],
    COMPLETED: [
      ViewAgr,
      SaveAsTemplate,
      DownloadPDF,
      AuditTrail,
      AutomateArchival,
      ExportFormData,
      ReportAbuse,
      HideShow,
      Share,
      Reminders,
      Notes,
      DownloadOriginatorFiles,
      FileAttachments,
      DownloadSignerIdentity,
      Delete
    ],
    WAITING_FOR_ME: [
      ViewAgr,
      Cancel,
      DownloadPDF,
      ModifyAgr,
      ReportAbuse,
      AuditTrail,
      ExportFormData,
      Reminders,
      HideShow,
      Share,
      Notes,
      DownloadOriginatorFiles,
      FileAttachments,
      DownloadSignerIdentity,
      Delete
    ],
    CANCELED: [
      ViewAgr,
      AuditTrail,
      HideShow,
      DownloadPDF,
      ReportAbuse,
      Notes,
      ExportFormData,
      DownloadOriginatorFiles,
      DownloadSignerIdentity,
      Delete
    ],
    AUTHORING: [
      ViewAgr,
      SaveAsTemplate,
      Edit,
      ModifyAgr,
      Cancel,
      HideShow,
      DownloadPDF,
      Notes,
      Delete
    ],

    WAITING_FOR_NOTARIZATION: [
      ViewAgr,
      Cancel,
      DownloadPDF,
      AuditTrail,
      ReportAbuse,
      ExportFormData,
      HideShow,
      Share,
      Reminders,
      Notes,
      DownloadOriginatorFiles,
      FileAttachments,
      DownloadSignerIdentity
    ],

    DELETED: [ViewAgr, DownloadPDF, Restore]
  };
