import React, { Component } from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ActionButton } from 'common/actionButton';
import { ActionSection } from 'common/styledElements';
import PowerAutomateIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/Power_Automate-16x.svg';
import { WithToastMessage } from 'as-ducati-core';
import { redirectAction } from 'utils/helper';

const LOCAL_STORAGE_KEY_FOR_IN_CONTEXT_ACTIONS = 'showPowerAutomateInContextActions';

export const isPowerAutomateInContextActionsV1Visible = props => {
  return (
    props.stores.Floodgate.hasPowerAutomateInContextActionsV1Enabled() &&
    window.localStorage.getItem(LOCAL_STORAGE_KEY_FOR_IN_CONTEXT_ACTIONS) === 'true'
  );
};

export const isPowerAutomateInContextActionsV2Visible = props => {
  const { Env, Floodgate } = props.stores;
  const { plugin, signcontext } = Env.plugins;
  const isInContextActionsV2Enabled = Floodgate.hasPowerAutomateInContextActionsV2Enabled();
  const isPowerAutomateVisible =
    window.localStorage.getItem(LOCAL_STORAGE_KEY_FOR_IN_CONTEXT_ACTIONS) === 'true';
  // TODO - Remove the plugins.plugin snippet once the Adobesign/core_app#14151 is in Prod
  const canUserCreatePowerAutomateWorkflows =
    plugin?.features?.canUserCreateAdvancedWorkflows ||
    signcontext?.features?.canUserCreateAdvancedWorkflows;

  return (
    isInContextActionsV2Enabled &&
    isPowerAutomateVisible &&
    Env.isPowerAutomateOrgProvisioningCompleted &&
    canUserCreatePowerAutomateWorkflows
  );
};

@inject('eventful', 'stores')
@observer
class AutomateAction extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.onClick.bind(this);
  }

  componentDidMount() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: this.props.componentName,
        type: 'action',
        waiting: false
      });
    }
  }

  @action
  onClick() {
    if (isPowerAutomateInContextActionsV2Visible(this.props)) {
      this.props.stores.Env.powerAutomateMode = true;
      this.props.stores.powerAutomate.powerAutomateCBVisible = true;
      this.props.stores.powerAutomate.powerAutomateCBType = this.props.componentName;
    } else {
      redirectAction(window.location.origin + this.props.navigateTo);
    }
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;
    const label = formatMessage({ id: this.props.labelId });

    return (
      <ActionSection>
        <ActionButton
          quiet
          label={label}
          icon={<PowerAutomateIcon />}
          className={this.props.className}
          analytics={this.props.analytics}
          onClick={this.clickHandler}
        />
      </ActionSection>
    );
  }
}

export default WithToastMessage(AutomateAction);
