import React, { Component, Fragment } from 'react';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Tab } from '@react/react-spectrum/TabView';
import {
  Cancel,
  DownloadOriginatorFiles,
  Edit,
  HideShow,
  Reminders,
  Share,
  ThumbnailView,
  ViewAgr,
  ReportAbuse
} from 'context-boards/agreement/actions';
import withContextBoard from 'common/withContextBoard';
import { Divider, StyledHeading } from 'common/styledElements';
import { ToggleCountable } from 'common/toggle-content';
import withTabView, { TabView, VIEWS } from 'common/tabView';
import Activity, { ActivitiesHeader } from 'components/activity';
import DownloadPDF from 'components/download-pdf';
import ExportFormDataView from 'components/export-data';
import RemindersView from 'components/reminders';
import StatusCountsView from 'components/status-counts';
import Notes from 'components/notes';
import { CONTEXT_BOARD_TYPES, TOGGLE_ACTIONS_MAXITEMS } from 'stores/constants';
import SummaryInfo from './summary-info';
import {
  MegaSignActivityReport,
  DownloadErrorReport,
  DownloadCompletedAgreements
} from './actions';

import * as classNames from '../classNames';

export const ExportFormData = props =>
  props.agreement.isSender ? <ExportFormDataView {...props} /> : null;

export const StatusCounts = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  props.stores.facets.state; // eslint-disable-line

  return <StatusCountsView {...props} />;
});

/**
 * Order specific.. make sure when adding actions they are in the right order
 */
const Actions = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  // props.agreement.visibility.observable.visibility; // eslint-disable-line
  // props.agreement.members.observable.senderInfo; // eslint-disable-line

  // NOTE: report abuse should be 5th so it shows up above the fold
  return (
    <Fragment>
      <ViewAgr labelId={'actions.view.megasign'} {...props} />
      <Edit labelId={'actions.edit_megasign'} {...props} />
      <Reminders {...props} />
      <Cancel type={CONTEXT_BOARD_TYPES.MEGASIGN} {...props} />
      <ReportAbuse {...props} />
      <DownloadPDF {...props} />
      <ExportFormData {...props} />
      <DownloadCompletedAgreements type={CONTEXT_BOARD_TYPES.MEGASIGN} {...props} />
      <DownloadErrorReport type={CONTEXT_BOARD_TYPES.MEGASIGN} {...props} />
      <DownloadOriginatorFiles {...props} />
      <HideShow type={CONTEXT_BOARD_TYPES.MEGASIGN} {...props} />
      <Share type={CONTEXT_BOARD_TYPES.MEGASIGN} {...props} />
      <MegaSignActivityReport {...props} />
      <Notes {...props} />
    </Fragment>
  );
});

Actions.displayName = 'AgreementActions';

const FullView = props => {
  const { formatMessage } = props.intl,
    actionsHeader = formatMessage({ id: 'section_headers.actions' });

  return (
    <Fragment>
      <ThumbnailView {...props} />
      <SummaryInfo
        className={classNames.SUMMARY_INFO_SECTION}
        type={CONTEXT_BOARD_TYPES.MEGASIGN}
        {...props}
      />
      <Divider />
      <StyledHeading>{actionsHeader}</StyledHeading>
      <ToggleCountable
        maxItems={TOGGLE_ACTIONS_MAXITEMS}
        className={classNames.SEE_MORE_ACTIONS}
        analyticsSubType="actions"
        eventful={props.eventful}
      >
        <Actions {...props} />
      </ToggleCountable>
      <Divider />
      <StatusCounts type={CONTEXT_BOARD_TYPES.MEGASIGN} {...props} />
      <ActivitiesHeader expanded={false} onClick={() => props.setView(VIEWS.ACTIVITIES)} />
    </Fragment>
  );
};

@inject('agreement', 'stores')
@observer
class MegaSignContextBoard extends Component {
  constructor(props) {
    super(props);
    this.base = props.base; // the HOC
    this.agreement.isSender = this.agreement.members.isSender();
    this.observable = this.props.stores.getObservableModel(this.agreement.documents);
    this.visibilityObservable = this.props.stores.getObservableModel(this.agreement.visibility);
    this.props.stores.bulksend = new this.props.stores.Api.Gateway.BulkSend.BulkSend({
      id: this.props.stores.agreement.id
    });
  }

  @computed
  get agreement() {
    // NOTE: 'agreement' is the generic REST model - here, it's a megaSign.
    return this.props.agreement;
  }

  render() {
    const { setFullView, setView, registerTabView } = this.props;
    const props = {
      ...this.props,
      hasDocRetention: this.agreement.get('documentRetentionApplied'),
      onClick: setFullView,
      setView: setView
    };

    return (
      <TabView
        selectedIndex={VIEWS.FULLVIEW} // controlled state (via API only)
        ref={ref => registerTabView(ref)}
      >
        <Tab key={VIEWS.FULLVIEW}>
          <FullView {...props} />
        </Tab>

        <Tab key={VIEWS.ACTIVITIES}>
          <Activity onClick={setFullView} />
        </Tab>

        <Tab key={VIEWS.REMINDERS}>
          <RemindersView {...this.props} expanded={true} onClick={setFullView} />
        </Tab>
      </TabView>
    );
  }
}
// @see withContextBoard
MegaSignContextBoard.getRestModel = Api => {
  return Api.MegaSigns.MegaSign;
};

// @see withContextBoard
MegaSignContextBoard.getFetchPromises = (model, opts) => [
  model.fetch(opts),
  model.members.fetch(opts),
  model.views.save({ name: 'ALL' })
];

const CB = withTabView(
  withContextBoard(MegaSignContextBoard, { displayName: 'MegaSignContextBoard' })
);

CB.getRestModel = MegaSignContextBoard.getRestModel;
export default CB;
