import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import Button from '@react/react-spectrum/Button';
import Wait from '@react/react-spectrum/Wait';
import Radio from '@react/react-spectrum/Radio';
import { analyticsFor } from 'utils/analytics';
import {
  DialogFooter,
  RadioGroupsWithNonTextLabels,
  LinkStyledButton
} from 'common/styledElements';
import { WithToastMessage } from 'as-ducati-core';
import stores from 'stores';
import PasswordView from 'common/password';
import PhoneView from './phone';
import DIGAuthenticationView from './digAuthentication';
import NameView from './kbaName';

const analytics = analyticsFor(analyticsFor.MEMBERS, 'editAuthentication');
const WIDGET_INSTANCE = 'WIDGET_INSTANCE';

// DCES-4302935: Super Set of all authentication method so we can maintain the position and order.
// Why keep this constant here?
// This array is used for any auth method that is in use in an agreement but not present in the list of methods returned by the API.
// The array maintains the index position where the missing auth option needs to be positioned.
// DCES-4249987: Excluding INHERITED_FROM_DOCUMENT
const ALL_AUTH_METHODS = [
  'NONE',
  'PASSWORD',
  'WEB_IDENTITY',
  'KBA',
  'PHONE',
  'ADOBE_SIGN',
  'GOV_ID',
  'DIG_ID',
  'EMAIL_OTP'
];
// participant types for widget
const WIDGET_FIRST_SIGNER = 'WIDGET_FIRST_SIGNER';
const WIDGET_COUNTER_SIGNER = 'WIDGET_COUNTER_SIGNER';
const WIDGET_PARTICIPANT_DEFINED_SIGNER = 'WIDGET_PARTICIPANT_DEFINED_SIGNER';

const Container = styled.div`
  overflow-x: hidden; // prevent x axis scrollbar
  min-height: ${props =>
    props.height
      ? props.height + 'px'
      : '350px'}; /* add a height to edit authentication so the overlay resizes correctly */
  &&& .spectrum-FieldGroup {
    display: block;

    label {
      width: 100%;
    }
  }
`;

const Help = styled.div`
  margin: 15px 0 5px 0;
  white-space: break-spaces;
`;

const StyledWait = styled(Wait)`
  z-index: 10;
`;

export let AUTH_METHODS;

// NOTE: tests MUST call stores.ready()
stores.whenReady().then(() => {
  AUTH_METHODS = stores.Api.Agreements.Members.AUTHENTICATION_METHODS;
});

export const isPassword = auth => auth === AUTH_METHODS.PASSWORD;
export const isPhone = auth => auth === AUTH_METHODS.PHONE;
export const isDigAuthentication = auth => auth === AUTH_METHODS.DIG_ID;
export const isKba = auth => auth === AUTH_METHODS.KBA;
const isWidget = type => type === 'widget';

@inject('agreement', 'stores')
@observer
class EditAuthentication extends Component {
  @observable
  preventUpdate = false;
  @observable
  loading = false;
  @observable
  ready = false; // ready to click 'confirm', no errors, and user has actually changed something
  @observable
  selectedValue;
  @observable
  displayPasswordView;
  @observable
  additionalDIGInfo;

  authenticationOptions;

  constructor(props) {
    super(props);
    this.perRecipientSettings = new this.props.stores.Api.Users.RecipientSettings({
      id: 'me'
    });

    this.securityOption = this.props.member.get('securityOption');
    this.isOnlySMSDelivery = this.checkOnlySMSDelivery();
    this.selectedValue = this.securityOption.authenticationMethod;
    this.hasPassword = isPassword(this.selectedValue);
    this.displayPasswordView = !this.hasPassword;
    this.isKbaNameRequiredAndNotWidget =
      props.stores.UserSettings.isKbaNameRequiredEnabled() && !this.isWidgetInstance(props);
    this.hideEditPhoneNumberOptionForWidgetFirstSigner =
      this.isWidgetInstance(props) && this.getWebformSignerType() === 'WIDGET_FIRST_SIGNER';
    this.isWidgetParticipantDefinedSigner =
      this.isWidgetInstance(props) &&
      this.getWebformSignerType() === 'WIDGET_PARTICIPANT_DEFINED_SIGNER';

    //DCES-4354622 - [KBA Pre-fill] First and Last name field not showing for WIDGET_PARTICIPANT_DEFINED_SIGNER(participant defined signers/unknown signers) when editing KBA from manage page
    //this is a safe check as nameInfo is populated only if kbaNameRequired setting is enabled
    // (for both agreement and widget agreement's participants those were initially unknown signers)
    this.isKbaNameRequiredAndWidgetPDS =
      props.stores.UserSettings.isKbaNameRequiredEnabled() &&
      this.isWidgetInstance(props) &&
      this.securityOption &&
      this.securityOption.nameInfo;

    if (isWidget(props.type)) {
      this.authenticationOptions = this.getAuthenticationOptionsForWidget();
    } else {
      this.authenticationOptions =
        // already available
        this.props.member.authenticationOptions ||
        (this.props.stores.UserSettings.getRequiresPerRecipientAuth() &&
        this.props.member.get('email')
          ? // fetch per recipient auth
            this.getPerRecipientSettings()
          : // get from settings
            this.props.stores.UserSettings.getAvailableAuthenticationMethods());
    }
    this.digAuthenticationInfo = this.props.stores.UserSettings.getDIGAuthenticationInfo();
  }

  @action
  isWidgetInstance(props) {
    return props.agreement.get('type') === WIDGET_INSTANCE || isWidget(props.type);
  }

  @action
  toggleChangeCurrentPassword() {
    const passwordInstance = this.passwordView.wrappedInstance;
    this.displayPasswordView = !this.displayPasswordView;

    if (!this.displayPasswordView) {
      // reset password
      this.ready = false;
      passwordInstance.reset();
    }
  }

  @action
  onChangeTextInputs(isValid) {
    this.ready = isValid;
  }

  @action
  updateAdditionalDIGInfo(additionalInfo) {
    this.additionalDIGInfo = additionalInfo;
  }

  getPasswordView() {
    const { formatMessage } = this.props.stores.Intl;

    return isPassword(this.selectedValue) ? (
      <Fragment>
        {' '}
        {this.hasPassword && (
          <LinkStyledButton onClick={() => this.toggleChangeCurrentPassword()}>
            {this.displayPasswordView
              ? formatMessage({ id: 'cancel.title' })
              : formatMessage({ id: 'password.change_current' })}
          </LinkStyledButton>
        )}
        <PasswordView
          ref={el => (this.passwordView = el)}
          onValidate={s => this.onChangeTextInputs(s)}
          className={'edit-authentication-password'}
          displayView={this.displayPasswordView}
          style={{ zIndex: 1 }}
        />
      </Fragment>
    ) : null;
  }

  getPhoneView() {
    const securityOption = this.props.member.get('securityOption');
    // There is a possibility for witness that user's details and phone number is not provided yet,
    // and will be provided at the time of signing so this is a safe check to show if user is defined
    // else just update authentication.
    // (hideEditPhoneNumberOptionForWidgetFirstSigner) now widgetfirstsigner can have phone auth option but,
    // adding phone number box shouldn't appear, as it was expected at the time of form filling
    // for widget Participant Defined Signer shown only if there is phoneInfo to edit else not as it is filled by first signer

    let showPhoneView =
      (this.isWitness() ? this.isUserDefined() : true) &&
      !this.hideEditPhoneNumberOptionForWidgetFirstSigner &&
      (this.isWidgetParticipantDefinedSigner ? this.isPhoneInfoPresent() : true);

    return isPhone(this.selectedValue) && showPhoneView ? (
      <PhoneView
        ref={el => (this.phoneView = el)}
        onValidate={s => this.onChangeTextInputs(s)}
        phoneInfo={securityOption.phoneInfo}
      />
    ) : null;
  }

  isPhoneInfoPresent() {
    return this.securityOption.phoneInfo && Object.keys(this.securityOption.phoneInfo).length !== 0;
  }

  getNameView() {
    const securityOption = this.props.member.get('securityOption');

    return isKba(this.selectedValue) ? (
      <NameView
        ref={el => (this.nameView = el)}
        onChangeHandler={errors => this.onChangeTextInputs(errors)}
        nameInfo={securityOption.nameInfo}
      />
    ) : null;
  }

  /**
   * If per recipient setting is true, we need to make a call for each member to get authentication methods specifically
   * for that member
   * resourceType can be AGREEMENT or WIDGET
   */
  @action.bound
  getPerRecipientSettings(resourceType) {
    resourceType = resourceType ? resourceType : 'AGREEMENT';
    this.loading = true;
    this.perRecipientSettings
      .fetch({
        data: { 'recipient-email': this.props.member.get('email'), resourceType: resourceType }
      })
      .then(() => {
        this.perRecipientSettingsSuccess();
      })
      .catch(e => {
        this.editError(e);
      });
  }

  @action
  perRecipientSettingsSuccess() {
    this.authenticationOptions = this.perRecipientSettings.get('availableAuthenticationMethods');

    // save in member for subsequent invocation
    this.props.member.authenticationOptions = this.authenticationOptions;
    this.loading = false;
  }

  /**
   * Determine if a selected authentication option is still supported.
   * Use case:
   * An authentication option is no longer supported (deprecated) but
   * agreements have been created with recipient auth that has been deprecated.
   */
  isUnsupportedAuthenticationOption(auth) {
    // DCES-4302935: Web/Social should not be supported anymore
    if (auth === 'WEB_IDENTITY') return true;
    return !this.authenticationOptions.includes(auth);
  }

  /**
   * Method to that returns available authentication method in its positional order.
   */
  getAuthenticationOptions() {
    if (!this.authenticationOptions) {
      return [];
    }
    const currentOption = this.securityOption.authenticationMethod;
    const availableOptions = Array.from(this.authenticationOptions)
      .filter(auth => auth !== 'WEB_IDENTITY') // DCES-4302935: remove Web/Social from UI explicitly
      .filter(auth => auth !== 'DIG_ID' || this.digAuthenticationInfo.length !== 0) // DCES-4330876: remove DIG when no IDP is active
      .filter(auth => auth !== 'ADOBE_SIGN' || !this.isOnlySMSDelivery) // DCES-4474635: remove ADOBE_SIGN and EMAIL_OTP for 'Only SMS' delivery
      .filter(auth => auth !== 'EMAIL_OTP' || !this.isOnlySMSDelivery);
    // When an auth setting is missing but it is set as current option, we have to display the option to user for review/update.
    if (this.isUnsupportedAuthenticationOption(currentOption)) availableOptions.push(currentOption);

    //For Witness participant currently None and Phone Auth are only options
    if (this.isWitness()) {
      return ALL_AUTH_METHODS.filter(
        auth =>
          (auth === AUTH_METHODS.NONE || auth === AUTH_METHODS.PHONE) &&
          availableOptions.includes(auth)
      );
    }

    if (
      isDigAuthentication(this.selectedValue) &&
      this.props.stores.Floodgate.hasDIGAuthenticationEnabled()
    ) {
      availableOptions.push(AUTH_METHODS.DIG_ID);
    }
    return ALL_AUTH_METHODS.filter(auth => availableOptions.includes(auth));
  }

  getDIGAuthenticationView() {
    const securityOption = this.props.member.get('securityOption');

    return isDigAuthentication(this.selectedValue) ? (
      <DIGAuthenticationView
        ref={el => (this.digAuthView = el)}
        updateAdditionalInfo={s => this.updateAdditionalDIGInfo(s)}
        onChange={s => this.onChangeTextInputs(s)}
        digInfo={securityOption.digAuthInfo}
      />
    ) : null;
  }

  getWebformSignerType() {
    const { formatMessage } = this.props.stores.Intl;
    const { isCC } = this.props;
    const memberEmail = this.props.member.get('email');
    const memberName = this.props.member.get('name');
    const nameToShow = memberName || memberEmail;
    const unknownSignerName = formatMessage({ id: 'widget.participant_unknownName' });
    const isPDS =
      this.props.participantSet !== undefined &&
      this.props.participantSet.get('providerParticipantSetInfo') !== undefined;
    const isCounterSigner = nameToShow && nameToShow !== unknownSignerName && !isCC;

    return isCounterSigner
      ? WIDGET_COUNTER_SIGNER
      : isPDS
      ? WIDGET_PARTICIPANT_DEFINED_SIGNER
      : WIDGET_FIRST_SIGNER;
  }

  /**
   * Returns true for 'Only SMS' delivery
   * @returns {boolean}
   */
  checkOnlySMSDelivery() {
    return !!(
      this.props.member.get('email') &&
      this.props.member.get('email').endsWith(this.props.stores.Api.Utils.SMS_SUPU_EMAIL_DOMAIN)
    );
  }

  authenticationOptionsView() {
    const authenticationOptions = this.getAuthenticationOptions();
    if (!authenticationOptions.length) {
      return [];
    }
    const { formatMessage } = this.props.stores.Intl;

    return authenticationOptions.map(auth => (
      <Radio
        autoFocus={auth === this.selectedValue}
        disabled={this.loading}
        key={auth}
        onChange={r => this.changeRadio(r)}
        value={auth}
        label={formatMessage({ id: 'authentication.' + auth.toLowerCase() })}
      >
        {isPassword(auth) && this.getPasswordView()}
        {isPhone(auth) && this.getPhoneView()}
        {isDigAuthentication(auth) && this.getDIGAuthenticationView()}
        {(this.isKbaNameRequiredAndNotWidget || this.isKbaNameRequiredAndWidgetPDS) &&
          isKba(auth) &&
          this.getNameView()}
      </Radio>
    ));
  }

  /**
   * Additional information about the authentication methods. Take in user input if necessary (password/phone)
   */
  additionalInfo() {
    const { formatMessage } = this.props.stores.Intl;

    // if this.additionalDIGInfo is undefined and selectedValue is DIG_ID then in that case authentication.dig_id_info will be used.
    if (isDigAuthentication(this.selectedValue) && this.additionalDIGInfo) {
      return <Help>{this.additionalDIGInfo}</Help>;
    }

    return (
      <Help>
        {formatMessage({ id: 'authentication.' + this.selectedValue.toLowerCase() + '_info' })}
      </Help>
    );
  }

  /**
   * User clicked on 'confirm' after selecting a new authentication.
   */
  @action
  editAuthentication() {
    const ispassword = isPassword(this.selectedValue),
      isphone = isPhone(this.selectedValue),
      isdigAuth = isDigAuthentication(this.selectedValue),
      iskba =
        isKba(this.selectedValue) &&
        (this.isKbaNameRequiredAndNotWidget || this.isKbaNameRequiredAndWidgetPDS),
      skipPhoneValidationForWitness = this.isWitness() && !this.isUserDefined(), //skip validations if its witness and user is not defined yet
      validatePhone =
        isphone &&
        !skipPhoneValidationForWitness & !this.hideEditPhoneNumberOptionForWidgetFirstSigner &&
        (this.isWidgetParticipantDefinedSigner ? this.isPhoneInfoPresent() : true),
      passwordInstance = ispassword && this.passwordView.wrappedInstance, // https://github.com/mobxjs/mobx-react/issues/544
      phoneInstance = validatePhone && this.phoneView.wrappedInstance,
      nameInstance = iskba && this.nameView.wrappedInstance,
      digAuthInstance = isdigAuth && this.digAuthView.wrappedInstance,
      newVal = this.selectedValue,
      oldVal = this.securityOption.authenticationMethod;

    let invalid;
    if (ispassword) {
      invalid = passwordInstance.validate();
    } else if (validatePhone) {
      invalid = phoneInstance.validate();
    } else if (iskba) {
      invalid = nameInstance.hasErrors();
    } else {
      invalid = false;
    }

    if (invalid) {
      if (ispassword) {
        this.displayPasswordView = true;
      }
      this.ready = false;
      return;
    }

    let newAuth = {
      authenticationMethod: this.selectedValue
    };
    if (ispassword) newAuth.password = passwordInstance.getPassword();
    if (validatePhone) {
      newAuth.phoneInfo = phoneInstance.getValues();
    }
    if (iskba) newAuth.nameInfo = nameInstance.getValues();
    if (isdigAuth) newAuth.digAuthInfo = digAuthInstance.getValues();

    this.loading = true;

    analytics.setContext({
      editAuth: {
        previous: oldVal,
        current: newVal
      }
    });
    //resetting identityCheckInfo
    newAuth.identityCheckInfo = null;
    // get the sec opt sub-model from member's model (with blank attributes)
    let securityOptionModel = this.props.member.getModel().securityOptions;
    securityOptionModel
      .save(newAuth)
      .then(() => {
        // update sec option in member (observable)
        if (isdigAuth) {
          // in PUT call only provider ID is send in payload and in get call we get the complete info of that provider.
          newAuth.digAuthInfo = digAuthInstance.getCompleteProviderInfo();
        }
        this.props.member.set({ securityOption: newAuth });

        this.editSuccess();
      })
      .catch(e => {
        this.editError(e);
      });
  }

  editSuccess() {
    const { formatMessage } = this.props.stores.Intl;
    const successMsg = formatMessage({ id: 'edit_authentication.success' });
    analytics.success();
    this.props.showToast({ text: successMsg, type: 'success' });
    this.props.toggleOverlay(false);
  }

  @action
  editError(e) {
    analytics.failed(e);
    this.loading = false;
    this.props.toggleOverlay(false);
    this.props.showToast(e);
  }

  @action
  changeRadio(value) {
    this.selectedValue = value;
    const isUndefinedWitness = this.isWitness() && !this.isUserDefined();
    //enable confirm button for undefined witness having phone auth straightaway if - even if phone info is not provided
    this.ready =
      !isPassword(value) &&
      (isUndefinedWitness ||
        !(
          isPhone(value) &&
          !this.hideEditPhoneNumberOptionForWidgetFirstSigner &&
          (this.isWidgetParticipantDefinedSigner ? this.isPhoneInfoPresent() : true)
        )) &&
      !(isKba(value) && (this.isKbaNameRequiredAndNotWidget || this.isKbaNameRequiredAndWidgetPDS));
    this.preventUpdate =
      this.isUnsupportedAuthenticationOption(value) &&
      value === this.securityOption.authenticationMethod;
  }

  isWitness() {
    return this.props.participantSet && this.props.participantSet.get('role') === 'WITNESS';
  }

  isUserDefined() {
    return !!this.props.member.get('email');
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;
    const authenticationOptions = this.getAuthenticationOptions();
    const height = authenticationOptions.length ? authenticationOptions.length * 55 : undefined;

    return (
      <Container height={height}>
        {this.loading && <StyledWait centered />}
        <RadioGroupsWithNonTextLabels
          ref={el => (this.radioGroup = el)}
          defaultSelectedValue={this.selectedValue}
        >
          {this.authenticationOptionsView()}
        </RadioGroupsWithNonTextLabels>
        {this.additionalInfo()}
        {!this.loading && (
          <DialogFooter>
            <Button
              disabled={this.loading}
              onClick={() => this.props.toggleOverlay(false)}
              variant="secondary"
            >
              {formatMessage({ id: 'cancel.title' })}
            </Button>
            <Button
              variant="cta"
              disabled={this.loading || !this.ready || this.preventUpdate}
              onClick={() => this.editAuthentication()}
            >
              {formatMessage({ id: 'confirm' })}
            </Button>
          </DialogFooter>
        )}
      </Container>
    );
  }

  getAuthenticationOptionsForWidget() {
    let authOptions = [];
    switch (this.getWebformSignerType()) {
      case WIDGET_FIRST_SIGNER:
        authOptions =
          this.props.stores.UserSettings.getWidgetAvailableAuthenticationMethodsForWidgetSigner();
        if (!this.props.stores.Floodgate.hasWebformPhoneAuthForWidgetSignerEnabled()) {
          authOptions = authOptions.filter(auth => auth !== 'PHONE');
        }
        break;
      case WIDGET_PARTICIPANT_DEFINED_SIGNER:
        authOptions =
          this.props.stores.UserSettings.getWidgetAvailableAuthenticationMethodsForPDS();
        break;
      case WIDGET_COUNTER_SIGNER:
        authOptions = this.props.stores.UserSettings.getRequiresPerRecipientAuth()
          ? // fetch per recipient auth
            this.getPerRecipientSettings('WIDGET')
          : // get from settings
            this.props.stores.UserSettings.getWidgetAvailableAuthenticationMethodsForCS();
        break;
      default:
        break;
    }
    return authOptions;
  }
}

export default WithToastMessage(EditAuthentication);
