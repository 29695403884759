import React, { Component, Fragment } from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Tab } from '@react/react-spectrum/TabView';
import * as classNames from 'context-boards/classNames';
import { Divider } from 'common/styledElements';
import { StyledHeading } from 'common/styledElements';
import withTabView, { TabView, VIEWS } from 'common/tabView';
import { Edit, ThumbnailView, ViewAgr, ReportAbuse } from 'context-boards/agreement/actions';
import withContextBoard from 'common/withContextBoard';
import Owner from 'components/change-owner';
import DownloadPDF from 'components/download-pdf';
import ExportFormDataView from 'components/export-data';
import Notes from 'components/notes';
import OriginatorFilesView from 'components/originator-files';
import { ToggleCountable } from 'common/toggle-content';
import { CONTEXT_BOARD_TYPES, TOGGLE_ACTIONS_MAXITEMS } from 'stores/constants';
import SummaryInfo from './summary-info';
import { UseTemplate } from './actions';
import Remove from './remove';
import stores from 'stores';

const OriginatorFiles = observer(props => {
  // observable to react to
  props.agreement.documents.observable.models; // eslint-disable-line

  return props.isSelf ? <OriginatorFilesView {...props} /> : null;
});

const ExportFormData = props => {
  // Download Form Field Data is visible only for creator or an advanced account sharee with SEND permissions
  const canExportFormData =
    props.isSelf ||
    (props.stores.accountSharing.isAcctSwitched() && !props.stores.accountSharing.cannotSend());

  return canExportFormData ? (
    <ExportFormDataView className={classNames.EXPORT_FORM_DATA_SECTION} {...props} />
  ) : null;
};

const Actions = props => {
  return (
    <Fragment>
      <ViewAgr labelId={'actions.view.library_document'} {...props} />
      <UseTemplate {...props} />
      <Edit labelId={'library_documents.actions.edittemplate'} {...props} />
      <Remove {...props} className={classNames.REMOVE_TEMPLATE_SECTION} />
      <ReportAbuse {...props} />
      <DownloadPDF className={classNames.DOWNLOAD_PDF_SECTION} {...props} />
      <OriginatorFiles {...props} />
      <ExportFormData {...props} />
      {props.isSelf ? <Notes className={classNames.NOTES_SECTION} {...props} /> : null}
      {props.showNewOwner ? (
        <Owner
          className={classNames.OWNER_SECTION}
          {...props}
          type={CONTEXT_BOARD_TYPES.LIBRARY_DOCUMENT}
        />
      ) : null}
    </Fragment>
  );
};

const FullView = props => {
  const { formatMessage } = props.intl,
    actionsHeader = formatMessage({ id: 'section_headers.actions' });

  return (
    <Fragment>
      <ThumbnailView {...props} />
      <SummaryInfo className={classNames.SUMMARY_INFO_SECTION} {...props} />
      <Divider />
      <StyledHeading>{actionsHeader}</StyledHeading>
      <ToggleCountable
        maxItems={TOGGLE_ACTIONS_MAXITEMS}
        className={classNames.SEE_MORE_ACTIONS}
        analyticsSubType="actions"
        eventful={props.eventful}
      >
        <Actions {...props} />
      </ToggleCountable>
    </Fragment>
  );
};

@inject('agreement', 'stores')
@observer
class LibraryDocumentContextBoard extends Component {
  @observable
  isSelf = null;

  constructor(props) {
    super(props);
    this.base = props.base; // the HOC
    this.observable = this.props.stores.getObservableModel(this.props.agreement.documents);
  }

  get agreement() {
    // NOTE: 'agreement' is the generic REST model - here, it's a lib. doc.
    return this.props.agreement;
  }

  get views() {
    return this.agreement.views;
  }

  render() {
    const { setFullView, setView, registerTabView } = this.props;

    if (!this.isSelf) {
      // There is no members call for libraryDocuments, so need to see if the
      // edit view is returned in the POST /views call to determine self
      const viewsObject = this.agreement.views.list.findWhere({ name: 'EDIT' });
      if (viewsObject !== undefined) {
        this.isSelf = true;
      }
    }

    if (
      this.props.stores.UserSettings.showOwnerActionOnManagePage() &&
      (this.props.stores.User.isAccountAdmin() || this.props.stores.User.isGroupAdmin())
    ) {
      this.showNewOwner = true;
    } else {
      this.showNewOwner = false;
    }

    const props = {
      ...this.props,
      hasDocRetention: this.agreement.get('documentRetentionApplied'),
      isSelf: this.isSelf,
      onClick: setFullView,
      setView: setView,
      showNewOwner: this.showNewOwner
    };

    return (
      <TabView
        selectedIndex={VIEWS.FULLVIEW} // controlled state (via API only)
        ref={ref => registerTabView(ref)}
      >
        <Tab key={VIEWS.FULLVIEW}>
          <FullView {...props} />
        </Tab>
      </TabView>
    );
  }
}
// @see withContextBoard
LibraryDocumentContextBoard.getRestModel = Api => {
  return Api.LibraryDocuments.LibraryDocument;
};

// @see withContextBoard
LibraryDocumentContextBoard.getFetchPromises = (model, opts) => {
  const mixedMode = stores.Api.LibraryDocuments.LibraryDocument.SHARING_MODE.MIXED;
  return [
    // Fetch the agreement first since we need the agreement name.
    model.fetch(opts).then(() => {
      const sharingMode = model.get('sharingMode');
      const promises = [
        model.views.save({
          name: 'ALL',
          sendViewConfiguration: {
            agrName: model.get('name')
          }
        })
      ];
      // If the sharingMode is MIXED, include fetching the shares as well
      if (sharingMode === mixedMode) {
        promises.push(model.shares.fetch(opts));
      }
      return Promise.all(promises);
    }),
    model.events.fetch(opts)
  ];
};

const CB = withTabView(
  withContextBoard(LibraryDocumentContextBoard, { displayName: 'LibraryDocumentContextBoard' })
);

CB.getRestModel = LibraryDocumentContextBoard.getRestModel;
CB.getFetchPromises = LibraryDocumentContextBoard.getFetchPromises;
export default CB;
